import React from 'react'
import {PageProps} from 'gatsby'
import ServiceContent from '../../components/ServiceContent'
import { servicesBreadcrumbElement } from '.'
import { LayoutProps } from '../../components/Layout.context'
import { Helmet } from 'react-helmet'
import {magasinLink, qualiReparLink} from "../../data/menu";
import Button from "../../components/Button";
import theme from "../../theme/theme";

const title = 'QualiRépar'

const QualiReparPage: React.FC<PageProps> = () => {
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    title="Bonus Réparation : Prolongez la vie de vos appareils avec Save !"
                    content="Obtenez 25€ de réduction immédiate sur la réparation de votre smartphone avec le Bonus
                    Réparation dans nos magasins SAVE labellisés QualiRépar"
                />
            </Helmet>

            <ServiceContent>
                <h1 style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: "28px",
                    color: theme.colors.secondary,
                    marginBottom: "10px"
                }}>
                    Bonus Réparation : Prolongez la vie de vos appareils avec Save !
                </h1>
                <ServiceContent.ResponsiveImage
                    desktopSrc="/images/services/5_QualiRepar/1-desktop_header.png"
                    mobileSrc="/images/services/5_QualiRepar/1-mobile_header.png"
                    alt="header"
                    desktopWidth="100%"
                />
                <ServiceContent.Paragraph>
                    Parce que Save s’engage toujours pour une consommation plus responsable et durable, nous sommes
                    fiers de vous offrir 25€ de réduction immédiate* dans notre
                    réseau de magasins labellisés QualiRépar !
                </ServiceContent.Paragraph>

                <ServiceContent.ButtonContainer>
                    <Button.Link to={`${magasinLink}`}>
                        Je me rends en magasin
                    </Button.Link>
                </ServiceContent.ButtonContainer>
                <ServiceContent.Title>
                    Le Bonus Réparation, qu’est-ce que c’est ?
                </ServiceContent.Title>
                <ServiceContent.Paragraph>
                    Le Bonus Réparation est une initiative visant à encourager les consommateurs à réparer leurs
                    appareils électroniques plutôt que de les remplacer. Seuls les magasins audités et labellisés
                    QualiRépar peuvent en faire bénéficier leurs clients.
                </ServiceContent.Paragraph>
                <ServiceContent.Paragraph>
                    Chez Save, tous nos magasins ont obtenu cette labellisation ! Vous pouvez donc bénéficier de 25€ de
                    réduction immédiate sur votre réparation.
                </ServiceContent.Paragraph>
                <ServiceContent.Title>
                    Comment puis-je bénéficier du bonus réparation ?
                </ServiceContent.Title>
                <ServiceContent.Paragraph>
                    Rien de plus simple : il vous suffit de vous rendre en magasin avec votre téléphone cassé. Si votre
                    réparation est éligible, 25€ seront déduits directement sur votre facture !
                </ServiceContent.Paragraph>
                <ServiceContent.ResponsiveImage
                    desktopSrc="/images/services/5_QualiRepar/2-desktop_parcours.png"
                    mobileSrc="/images/services/5_QualiRepar/2-mobile_parcours.png"
                    alt="Parcours"
                    desktopWidth="820px"
                />
                <ServiceContent.Title>
                    Ma réparation est-elle éligible au Bonus Réparation ?
                </ServiceContent.Title>
                <ServiceContent.Paragraph>
                    Le Bonus Réparation couvre une large gamme de réparations courantes pour vos smartphones et
                    tablettes afin de vous offrir une solution économique et écologique.
                </ServiceContent.Paragraph>
                <ServiceContent.ResponsiveImage
                    desktopSrc="/images/services/5_QualiRepar/3-desktop_rep-eligibles.png"
                    mobileSrc="/images/services/5_QualiRepar/3-mobile_rep-eligibles.png"
                    alt="rep-eligibles"
                    desktopWidth="820px"
                />
                <ServiceContent.SaveList
                    image="/images/services/5_QualiRepar/picto_save.svg"
                    title={[
                        "Écran Fissuré ou Cassé",
                        "Problèmes de Batterie",
                        "Connecteurs de Charge Endommagés",
                        "Appareil Photo Défectueux",
                        "Boutons et Commandes",
                        "Problèmes Audio",]}
                    desc={[
                        " : remplacement d’écrans endommagés pour retrouver une visibilité optimale et protéger l'intégrité de votre appareil.",
                        " : remplacement de batteries défectueuses ou usées pour assurer une autonomie et prolonger la durée de vie de votre téléphone.",
                        " : réparation ou remplacement des ports de charge pour garantir une recharge efficace de votre smartphone.",
                        " : réparation ou remplacement des caméras avant et arrière pour des photos et vidéos de qualité.",
                        " : réparation des boutons d'accueil, de volume, et d'alimentation pour une utilisation fluide de votre téléphone.",
                        " : réparation des haut-parleurs et des micros pour une qualité sonore impeccable.",]}
                />
                <ServiceContent.Title>
                    Pourquoi faire réparer mon appareil chez Save ?
                </ServiceContent.Title>
                <ServiceContent.Paragraph>
                    Tout simplement parce que Save est le réseau leader de la réparation en France ! Avec près de 150
                    magasins et des centaines de techniciens experts et formés par les plus grands constructeurs, vous
                    laissez votre téléphone ou tablette entre de bonnes mains.
                </ServiceContent.Paragraph>
                <ServiceContent.Paragraph>
                    Quelle que soit la marque de votre produit (iPhone, Samsung, Xiaomi…), nos experts pourront
                    effectuer sa réparation rapidement avec des pièces détachées de haute qualité.
                </ServiceContent.Paragraph>
                <ServiceContent.ResponsiveImage
                    desktopSrc="/images/services/5_QualiRepar/4-desktop_save.png"
                    mobileSrc="/images/services/5_QualiRepar/4-mobile_save.png"
                    alt={"save"}
                    desktopWidth="820px"
                />
                <ServiceContent.Paragraph>
                    *Le bonus réparation QualiRépar est applicable sous condition d’acceptation par les éco-organisme
                    selon l’éligibilité du produit et de la panne. Voir conditions sur&nbsp;
                    <a href={"https://www." + qualiReparLink} target="_blank" style={{color: theme.colors.primary}}>
                        {qualiReparLink}
                    </a>
                </ServiceContent.Paragraph>
            </ServiceContent>
        </>
    )
}

const layoutProps: LayoutProps = {
    title:
        'Bonus Réparation : Prolongez la vie de vos appareils avec Save !',
    breadcrumb: [servicesBreadcrumbElement, { label: title }],
}

export default Object.assign(QualiReparPage, {
    layoutProps,
})
